
import { QuestionModel } from "@/models";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            type: Object as PropType<QuestionModel>,
            required: true
        }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        const value = computed({
            get: () => props.modelValue,
            set: (value: unknown) => context.emit("update:modelValue", value)
        });

        const answer = computed({
            get: () => props.modelValue.discussionAnswer ?? "",
            set: (val: unknown) => value.value.discussionAnswer = val as string
        });

        return { value, labelId: `label-${props.modelValue.id}`, answer };
    }
});
