
import { computed, defineComponent, ref, watch } from "vue";

import { QuestionModel, QuizModel, StudentQuizAttemptModel } from "@/models";
import { AppActionTypes, useStore } from "@/store";
import { loadData } from "@/helpers";

import MultipleChoiceQuestion from "@/components/quiz/MultipleChoiceQuestion.vue";
import DiscussionQuestion from "@/components/quiz/DiscussionQuestion.vue";
import QuizResults from "@/components/quiz/QuizResults.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default defineComponent({
    components: {
        "progress-bar": ProgressBar,
        "multiple-choice-question": MultipleChoiceQuestion,
        "discussion-question": DiscussionQuestion,
        "quiz-results": QuizResults
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const { getters } = store;

        const quiz = ref<QuizModel | null>(null);
        const quizResults = ref<StudentQuizAttemptModel | null>(null);

        const currentQuestionIndex = ref<number>(0);
        const currentQuestion = ref<QuestionModel | null>(null);
        const isComplete = ref<boolean>(false);

        // Watch the current question index and change the current question
        watch(
            () => currentQuestionIndex.value,
            () =>
                (currentQuestion.value =
                    currentQuestionIndex.value >= 0 &&
                    quiz.value &&
                    quiz.value.questions
                        ? quiz.value.questions[currentQuestionIndex.value]
                        : null)
        );

        const setQuiz = async (): Promise<void> => {
            const quizResponse = await store.dispatch(
                AppActionTypes.getQuiz,
                props.id
            );

            if (quizResponse.data) {
                quiz.value = quizResponse.data;
            }

            // Set the current question to the first one
            if (quiz.value && quiz.value.questions) {
                currentQuestion.value =
                    quiz.value.questions[currentQuestionIndex.value];
            }
        };

        loadData(store, async () => {
            setQuiz();
        });

        const submit = async (): Promise<void> => {
            // Display results
            isComplete.value = true;

            const data: StudentQuizAttemptModel = {
                quizId: quiz.value?.id,
                questions: quiz.value?.questions
            };

            const response = await store.dispatch(
                AppActionTypes.createStudentQuiz,
                {
                    model: data,
                    courseId: props.courseId,
                    lessonId: props.lessonId,
                    quizId: props.id
                }
            );

            if (response.data) {
                quizResults.value = response.data;
            }
        };

        const canClickNext = (): boolean => {
            // Discussion questions must be >= 10 characters if there is answer
            if (currentQuestion.value?.isDiscussion) {
                return currentQuestion.value.discussionAnswer
                    ? currentQuestion.value.discussionAnswer?.length >= 10
                    : true;
            }

            // Multiple choice must have a selected option
            return (
                !!currentQuestion.value &&
                !!currentQuestion.value.selectedOptionId
            );
        };

        // The user can click back if the first question is not being displayed
        const canClickBack = (): boolean => currentQuestionIndex.value > 0;

        // The user can click submit if the current question has an answer
        const canClickSubmit = (): boolean => canClickNext();

        // If try again was clicked, bring the user back to the first question
        const tryAgainClicked = async (): Promise<void> => {
            isComplete.value = false;
            quizResults.value = null;
            currentQuestionIndex.value = 0;
            quiz.value = null;

            setQuiz();
        };

        return {
            quiz,
            quizResults,
            currentQuestion,
            currentQuestionIndex,
            isComplete,
            canClickNext,
            canClickBack,
            canClickSubmit,
            submit,
            tryAgainClicked,
            loading: computed(() => getters.loading)
        };
    }
});
