import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col text-center" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.value.name), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value.answerOptions, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          key: option.id,
          type: "button",
          class: _normalizeClass(["mt-2 p-2 border text-center w-100 d-block", {
                    'bg-light': option.id !== _ctx.answer,
                    'bg-primary text-white font-weight-bold':
                        option.id === _ctx.answer
                }]),
          onClick: ($event: any) => (_ctx.optionClicked(option))
        }, _toDisplayString(option.name), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}