
import { AnswerOptionModel, QuestionModel } from "@/models";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            type: Object as PropType<QuestionModel>,
            required: true
        }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        const value = computed({
            get: () => props.modelValue,
            set: (value: unknown) => context.emit("update:modelValue", value)
        });

        const answer = computed({
            get: () => props.modelValue.selectedOptionId ?? 0,
            set: (val: unknown) => value.value.selectedOptionId = val as number
        });

        const optionClicked = (option: AnswerOptionModel): void => {
            if (!option.id) {
                return;
            }

            answer.value = option.id;
        };

        return { optionClicked, value, answer };
    }
});
