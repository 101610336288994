
import { StudentQuizAttemptModel } from "@/models";
import { computed, defineComponent, PropType } from "vue";
import { routeNames } from "@/router/routeNames";
import { useRoute } from "vue-router";
import constants from "./constants";

export default defineComponent({
    props: {
        quiz: {
            type: Object as PropType<StudentQuizAttemptModel>,
            required: true
        }
    },
    emits: ["tryAgain"],
    setup(props, { emit }) {
        const tryAgainClicked = (): void => {
            emit("tryAgain");
        };

        const quizScore = computed(() => {
            if (props.quiz.points && props.quiz.pointsPossible) {
                return props.quiz.points === 0
                    ? 0
                    : (props.quiz.points / props.quiz.pointsPossible) * 100;
            }

            return 0;
        });

        const isGreenFont = (): boolean =>
            quizScore.value >= constants.QUIZ_SCORE_THRESHOLD_UPPER;
        const isYellowFont = (): boolean =>
            !isGreenFont() &&
            quizScore.value >= constants.QUIZ_SCORE_THRESHOLD_LOWER;

        const route = useRoute();
        const lessonId = route.params.lessonId;
        const courseId = route.params.courseId;

        const displayStatus = computed(() => {
            if (quizScore.value === constants.QUIZ_SCORE_THRESHOLD_PERFECT) {
                return {
                    color: constants.QUIZ_SCORE_FONT_COLOR_GREEN,
                    text: "Perfect!"
                };
            } else {
                if (quizScore.value >= constants.QUIZ_SCORE_THRESHOLD_UPPER) {
                    return {
                        color: constants.QUIZ_SCORE_FONT_COLOR_GREEN,
                        text: "Passed"
                    };
                } else if (
                    quizScore.value >= constants.QUIZ_SCORE_THRESHOLD_LOWER
                ) {
                    return {
                        color: constants.QUIZ_SCORE_FONT_COLOR_YELLOW,
                        text: "Passed"
                    };
                }
                return {
                    color: constants.QUIZ_SCORE_FONT_COLOR_RED,
                    text: "Good Try!"
                };
            }
        });

        return {
            tryAgainClicked,
            quizScore,
            isGreenFont,
            isYellowFont,
            routeNames,
            lessonId,
            courseId,
            displayStatus
        };
    }
});
