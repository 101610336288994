import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff1c6014"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mt-0" }
const _hoisted_2 = { class: "col text-center" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", {
        id: _ctx.labelId,
        class: "mb-3"
      }, _toDisplayString(_ctx.value.name), 9, _hoisted_3),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
        class: "form-control",
        "aria-labelledby": _ctx.labelId
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.answer]
      ])
    ])
  ]))
}